import { combineReducers, configureStore } from '@reduxjs/toolkit';
import appReducer from '../../features/app/appSlice';
import langReducer from '../lang/langSlice';
import userReducer from '../../features/app/drivers/sidebar/tabs/user/userSlice';
import groupsReducer from '../../features/app/drivers/sidebar/tabs/groups/groupSlice';
import tokenHandlingReducer from '../tokenHandling/tokenHandlingSlice';
import loginReducer from '../login/loginSlice';
import sidebarReducer from '../../features/app/drivers/sidebar/sidebarSlice';

const rootReducer = combineReducers({
    app: appReducer,
    user: userReducer,
    groups: groupsReducer,
    lang: langReducer,
    login: loginReducer,
    tokenHandling: tokenHandlingReducer,
    sidebar: sidebarReducer,
});

export const setupStore = (preloadedState?: Partial<RootState>) =>
    configureStore({
        reducer: rootReducer,
        preloadedState,
    });

export const store = setupStore();

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
