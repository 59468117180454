import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { Tenant } from '../../../../configuration/types';
import { Driver, DriverToCreate } from '../../../../types';
import { CreateDriverDialog } from '../components/CreateDriverDialog';
import { createAndSelectDriver } from '../../thunks/createDriverThunks';
import {
    getDriverCreationInProgressRtk,
    getDriversRtk,
    getShowDriverCreationDialogRtk,
    toggleDriverCreationDialog,
} from '../../appSlice';
import { getTenant, getUserAccount } from '../../../../configuration/login/loginSlice';
import { RootState } from '../../../../configuration/setup/store';

export interface CreateDriverDialogPropsFromState {
    existingDrivers: Driver[];
    accountId: string | undefined;
    tenant: Tenant | undefined;
    showDialog: boolean;
    driverCreationInProgress: boolean;
}

export interface CreateDriverDialogPropsFromDispatch {
    createAndSelectDriver: (driver: DriverToCreate) => void;
    toggleDriverCreationDialog: (show: boolean) => void;
}

const mapStateToProps = (state: RootState): CreateDriverDialogPropsFromState => ({
    existingDrivers: getDriversRtk(state),
    accountId: getUserAccount(state),
    tenant: getTenant(state),
    showDialog: getShowDriverCreationDialogRtk(state),
    driverCreationInProgress: getDriverCreationInProgressRtk(state),
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>): CreateDriverDialogPropsFromDispatch => ({
    createAndSelectDriver: (driver: DriverToCreate) => dispatch(createAndSelectDriver(driver)),
    toggleDriverCreationDialog: (show: boolean) => dispatch(toggleDriverCreationDialog(show)),
});

export const CreateDriverDialogContainer = connect(mapStateToProps, mapDispatchToProps)(CreateDriverDialog);
