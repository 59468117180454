import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { accessToken } from './accessToken.ts';
import { AccessToken } from '../types';
import { RootState } from '../setup/store';

export interface TokenHandlingState {
    accessToken: AccessToken;
    idToken: string | null;
}

const initialState: TokenHandlingState = {
    accessToken: accessToken.getAccessToken(),
    idToken: null,
};

const tokenHandlingSlice = createSlice({
    name: 'tokenHandling',
    initialState,
    reducers: {
        accessTokenStored: (state, action: PayloadAction<AccessToken>) => {
            state.accessToken = action.payload;
        },
        idTokenStored: (state, action: PayloadAction<string | null>) => {
            state.idToken = action.payload;
        },
    },
});

// Actions
export const { accessTokenStored, idTokenStored } = tokenHandlingSlice.actions;

// Selectors
export const getAccessToken = (state: RootState): string =>
    state.tokenHandling?.accessToken ? state.tokenHandling.accessToken : 'NO_ACCESS_TOKEN_AVAILABLE';

export const getIdToken = (state: RootState) => state.tokenHandling?.idToken;

export default tokenHandlingSlice.reducer;
